<!--
  Doel: Lijstje van loginDetails
 -->
<template>
  <div>
    <VBtn
      class="mb-5"
      color="primary"
      @click="isAddLdDialogOpen = !isAddLdDialogOpen"
    >
      <v-icon>{{ icons.mdiKeyPlus }}</v-icon>

      <span class="mx-2">
        Inloggegevens Toevoegen
      </span>
    </VBtn>

    <VBtn
      class="mb-5 ml-5"
      color="primary"
      @click="printLabels"
    >
      <v-icon>{{ icons.mdiPrinterOutline }}</v-icon>

      <span class="mx-2">
        Labels afdrukken
      </span>
    </VBtn>

    <!-- Login details -->
    <v-expansion-panels
      v-if="loginDetails.length != 0"
      inset
    >
      <v-expansion-panel
        v-for="ld in loginDetails"
        :key="ld.id"
      >
        <v-expansion-panel-header>
          <b>{{ ld.website.name || 'Fout bij inladen van websites. Refresh de pagina, aub.' }}</b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p
            v-for="cred in ld.credentials"
            :key="cred.fieldName"
          >
            <v-btn
              icon
              color="accent"
              @click="editCredential(ld, cred)"
            >
              <v-icon>
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>
            <b>{{ cred.fieldName }}:</b> {{ cred.fieldValue }}
          </p>
          <VBtn
            color="error"
            small
            @click="removeLoginDetail(ld)"
          >
            <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            Verwijderen
          </VBtn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- No login details found -->
    <v-card
      v-else
    >
      <v-card-text>
        <v-icon>
          {{ icons.mdiAccountAlertOutline }}
        </v-icon>
        <span class="ml-2 font-weight-bold">
          Geen inloggegevens gevonden...
        </span>
      </v-card-text>
    </v-card>

    <!-- Add login details modal -->
    <add-login-details-modal
      :is-add-ld-dialog-open.sync="isAddLdDialogOpen"
      :user-data="user"
      :websites="websites"
      :allowed-websites-type="userType"
      v-on="$listeners"
      @refetch-data="refetchData"
    ></add-login-details-modal>
  </div>
</template>

<script>
import axios from '@axios'
import {
  mdiAccountAlertOutline, mdiKeyPlus, mdiPencilOutline, mdiTrashCanOutline, mdiPrinterOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import AddLoginDetailsModal from './addLoginDetailsModal.vue'

function toast(status, title, text, duration = 3000) {
  Swal.fire({
    position: 'top-end',
    icon: status,
    title,
    text,
    showConfirmButton: false,
    timer: duration,
    toast: true,
  })
}

export default {
  components: {
    AddLoginDetailsModal,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    userType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // Data
    const websites = ref([])
    const loginDetails = ref([])
    const isAddLdDialogOpen = ref(false)

    // Functions
    const refetchData = () => {
      // Get websites
      axios.get('/website').then(websiteResp => {
        if (websiteResp.status === 200) {
          websites.value = websiteResp.data.websites
        }
      })

      // Get login details
      axios.get(`/account/${props.user.id}/logindetails`).then(ldResp => {
        if (ldResp.status === 200) {
          loginDetails.value = ldResp.data.loginDetails.map(ld => {
            const website = websites.value.find(w => w.id === ld.website)
            const newLd = {
              ...ld,
              website,
            }

            return newLd
          })
        }
      })
    }
    refetchData()

    const printLabels = async () => {
      const usersWithQr = []
      usersWithQr.push(props.user)

      const ldIds = usersWithQr.map(user => user.id)
      localStorage.setItem('ldIds', JSON.stringify(ldIds))

      window.open('/print/label', '_blank')
    }

    async function editCredential(ldToEdit, credential) {
      // First, make a copy of the loginDetails
      const newLoginDetails = JSON.parse(JSON.stringify(ldToEdit))

      // Next, find the credential in the loginDetails
      const credIndex = newLoginDetails.credentials.findIndex(
        c => c.fieldName === credential.fieldName,
      )

      // Prompt the user for the new value
      const { value: newValue } = await Swal.fire({
        title: `Bewerk ${credential.fieldName}`,
        input: 'text',
        inputValue: credential.fieldValue,
        showCancelButton: true,
        confirmButtonText: 'Opslaan',
        cancelButtonText: 'Annuleren',
        // eslint-disable-next-line consistent-return
        inputValidator: value => {
          if (!value) {
            return 'Gelieve iets in te vullen of te annuleren'
          }
        },
      })

      // If the user cancelled, return
      if (!newValue) return

      // Update the credential
      newLoginDetails.credentials[credIndex].fieldValue = newValue

      // Update the login details
      axios
        .put(`/account/${props.user.id}/logindetails/${ldToEdit.id}`, {
          credentials: newLoginDetails.credentials,
        })
        .then(resp => {
          if (resp.status === 200) {
            toast('success', 'Gelukt!', 'De inloggegevens zijn aangepast')
            refetchData()
          } else {
            toast('error', 'Fout!', 'Er is iets misgegaan')
          }
        })
    }

    async function removeLoginDetail(ld) {
      Swal.fire({
        title: 'Opgelet!',
        text: `Ben je zeker dat je de inloggegevens van ${props.user.firstName} ${props.user.lastName} voor ${ld.website.name} wilt verwijderen?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ja, verwijderen',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await axios.delete(`/account/${props.user.id}/logindetails/${ld.id}`)
          if (response.data.success) {
            refetchData()

            return toast('success', 'Gelukt!', 'De inloggegevens zijn verwijderd')
          }

          return toast('error', 'Probeer het later opnieuw...', 'Er is iets misgelopen bij het verwijderen van de inloggegevens.')
        }

        return false
      })
    }

    return {
      // Data
      websites,
      loginDetails,
      isAddLdDialogOpen,

      // Functions
      refetchData,
      editCredential,
      removeLoginDetail,
      printLabels,

      // Icons
      icons: {
        mdiPencilOutline,
        mdiAccountAlertOutline,
        mdiKeyPlus,
        mdiTrashCanOutline,
        mdiPrinterOutline,
      },
    }
  },
}
</script>

<style>
  .swal2-popup {
      font-family: "Inter", sans-serif;
  }
  </style>
