<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isAddLdDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-add-ld-dialog-open', false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <!-- Card header -->
      <v-card-title class="justify-center text-h5">
        Nieuwe inloggegevens
      </v-card-title>

      <!-- Card subtitle -->
      <v-card-text class="text-center mt-n2">
        Reeds bestaande inloggegevens voor deze website worden overschreven
      </v-card-text>

      <!-- Website dropdown -->
      <v-card-text>
        <v-row>
          <!-- Klas -->
          <v-col cols="12">
            <v-select
              v-model="selectedWebsite"
              :items="allowedWebsites"
              item-text="name"
              item-value="id"
              outlined
              dense
              label="Website"
              no-data-text="Geen websites gevonden"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Login fields -->
      <v-card-text v-if="isAddLdDialogOpen && !selectedWebsiteFieldsLoading && selectedWebsite != null">
        <v-form class="multi-col-validation">
          <v-row>
            <!-- Voornaam -->
            <v-col
              v-for="loginField in loginFields"
              :key="loginField.fieldName"
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="loginField.fieldValue"
                outlined
                dense
                :label="firstLetterUpper(loginField.fieldName)"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                :disabled="!allowSubmit"
                @click.prevent="onBtnAddClick"
              >
                Toevoegen
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-add-ld-dialog-open', false)"
              >
                Annuleren
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import { computed, ref, watch } from '@vue/composition-api'
import Swal from 'sweetalert2'

export default {
  props: {
    isAddLdDialogOpen: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    websites: {
      type: Array,
      required: true,
    },
    allowedWebsitesType: {
      type: String,
      required: true,
    },
  },
  methods: {
    firstLetterUpper(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
  },

  setup(props, { emit }) {
    // Data
    const loginFields = ref([
      {
        fieldName: 'firstname',
        fieldValue: null,
      },
      {
        fieldName: 'lastname',
        fieldValue: null,
      },
      {
        fieldName: 'email',
        fieldValue: null,
      },
      {
        fieldName: 'password',
        fieldValue: null,
      },
    ])

    const selectedWebsite = ref(null)
    const selectedWebsiteFieldsLoading = ref(false)
    const userDataLocal = ref({})
    userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
    userDataLocal.value.selectedClass = userDataLocal.value.class ? userDataLocal.value.class.id : 'none'

    console.log(props.websites)

    // Functions
    function toast(status, title, text, duration = 3000) {
      Swal.fire({
        position: 'top-end',
        icon: status,
        title,
        text,
        showConfirmButton: false,
        timer: duration,
        toast: true,
      })
    }

    const onBtnAddClick = async () => {
      try {
        console.log(userDataLocal.value)

        const loginDetailsAdded = await axios.post(`/website/${selectedWebsite.value}/logindetails`, {
          account: props.userData,
          credentials: loginFields.value,
        })
        if (loginDetailsAdded.data.success) {
          toast('success', 'Opgeslagen', 'Inloggegevens zijn toegevoegd')
          selectedWebsite.value = null
        } else {
          toast('error', 'Er ging iets mis', 'Probeer het opnieuw')
        }

        // Update login method
        // Not implemented yet
        // Refresh user data
      } catch (error) {
        // toast('error', 'Foutief wachtwoord', 'Probeer het opnieuw')
        console.log(error)
      } finally {
        emit('refetch-data')
        emit('update:is-add-ld-dialog-open', false)
      }
    }

    // Watchers
    watch(selectedWebsite, async () => {
      selectedWebsiteFieldsLoading.value = true

      if (selectedWebsite.value == null) {
        selectedWebsiteFieldsLoading.value = false
        loginFields.value = []

        return
      }

      axios.get(`/website/${selectedWebsite.value}/fields`).then(fieldResp => {
        if (fieldResp.status === 200) {
          loginFields.value = fieldResp.data.fieldNames.map(field => ({
            fieldName: field,
            fieldValue: null,
          }))

          selectedWebsiteFieldsLoading.value = false
        }
      })
    })

    // Computed properties
    const allowedWebsites = computed(() => {
      if (props.allowedWebsitesType === 'Pupil') {
        return props.websites.filter(website => website.canBeUsedByStudents)
      } if (props.allowedWebsitesType === 'Teacher') {
        return props.websites.filter(website => website.canBeUsedByTeachers)
      }

      return props.websites
    })

    const allowSubmit = computed(() => {
      if (selectedWebsite.value === null) return false

      return loginFields.value.every(field => field.fieldValue !== null && field.fieldValue !== '')
    })

    return {
      // Data
      userDataLocal,
      loginFields,
      selectedWebsite,
      selectedWebsiteFieldsLoading,
      allowedWebsites,
      allowSubmit,

      // Methods
      onBtnAddClick,
    }
  },
}
</script>
